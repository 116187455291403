import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import QrReader from 'react-qr-scanner';
import { useNavigate } from 'react-router-dom';

function QRScanner(props) {
    const navigate = useNavigate();
    const previewStyle = {
        height: 500,
        width: 500,
    }
    const url = "https://els.moetech.at/product?";
    const [width, setWidth] = useState(window.innerWidth);
    const [text, setText] = useState("");
    const [cam, setCam] = useState("environment");

    let isDesktop = (width > 768);

    function handleScan(data) {
        if (data) {
            let result = data.text;
            if (result.substring(0, 31) === url) {
                let remaining = result.substring(31, 50);
                navigate('/product?' + remaining);
            }
            else {
                setText(result);
            }
        }
    }

    function handleError(err) {
        console.error(err)
    }

    return (
        <>
            <Container className="px-3 mb-5">
                <div className="btn float-right px-0" onClick={(e) => setCam(cam == "environment" ? "user" : "environment")} role="button">
                <i className="fa-solid fa-camera-rotate"></i> Switch
                </div>
                {cam == "environment" ? (
                    <QrReader
                        delay="200"
                        style={previewStyle}
                        onError={handleError}
                        onScan={handleScan}
                        className="w-100"
                        constraints={
                            isDesktop
                                ? undefined
                                : {
                                    video: {
                                        facingMode: { exact: "environment" }
                                    }
                                }
                        }
                    />
                ) : (
                    <QrReader
                        delay="200"
                        style={previewStyle}
                        onError={handleError}
                        onScan={handleScan}
                        className="w-100"
                        constraints={
                            isDesktop
                                ? undefined
                                : {
                                    video: {
                                        facingMode: { exact: "user" }
                                    }
                                }
                        }
                    />)}
                {text ? (<div className='mt-2'>
                    <p>URL konnte nicht aufgelöst werden:<br />
                        {text}</p>
                </div>) : (<></>)}
            </Container>
        </>
    )
}

export default QRScanner
