import React from "react";
import { Button } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {

  const navigate = useNavigate()

  return (
    <>
      <div className="navbar-custom topnav-navbar topnav-navbar-light p-2">
        <div className="container p-2 px-md-3">
          <Link to="/" >
            <img src="/img/economic_logo.png" alt="" height="48" />
            <h3 className="d-inline text-dark align-middle ml-2">Spec Sheet</h3>
          </Link>
          
          <nav className="navbar navbar-light navbar-expand topnav-menu float-right pt-1 p-0">
            <div className="collapse navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link btn rounded-pill btn-info text-white" to="/qr-scan" role="button" aria-haspopup="true" aria-expanded="false">
                    <i className="fa-solid fa-qrcode"></i> Scan
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  )
};

export default Layout;