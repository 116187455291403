import axios from 'api/axios';
import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function QrGenerator(props) {
    const [ecoNr, setEcoNr] = useState("C");
    const [useNumPad, setUseNumPad] = useState(false);
    const inputEcoRef = useRef();
    const [qr, setQR] = useState();
    const url = "https://els.moetech.at/product?e=";


    useEffect(() => {
        //inputCustRef.current.focus();
        //inputEcoRef.current.focus();
    }, [useNumPad])

    function inputChange(event) {
        switch (event.target.name) {
            case "ecoNr":
                setEcoNr(event.target.value);
                break;
            default:
        }
    }

    function onKeyUp(event) {
        if (event.key === "Enter") {
            switch (event.target.name) {
                case "ecoNr":
                    getQR(event)
                    break;
                default:
            }
        }
    }

    function getQR(event) {
        if (ecoNr && ecoNr != "C") {
            setQR("https://els-api.moetech.at/qr?e=" + ecoNr)
            /*axios
            .get(`qr?e=${ecoNr}`, {
              headers: {
                responseType: 'blob',
                "x-access-token": localStorage.getItem("token")
              }
            })
            .then((response) => {
                console.log(response.data)
                let imageBlob = URL.createObjectURL(response.data)
                setQR(imageBlob);
            })
            .catch((error) => props.showError(error));
            */
        }
        else {
            props.showError("Fehlende Artikelnummer")
        }
    }

    return (
        <>
            <Container className="px-3 mb-5">
                <Row className="mt-4">
                    <Col lg="6">
                        <Card>
                            <Card.Body>
                                <p>Nur Zahlen bei Tastatur verwenden</p>
                                <input type="checkbox" id="switch2" data-switch="info" checked={useNumPad} onChange={(e) => setUseNumPad(e.target.checked)} />
                                <label htmlFor="switch2" data-on-label="Ein" data-off-label="Aus"></label>
                                <div className="my-3">
                                    <label className="form-label">Economic Art-Nr.</label>
                                    <div className="input-group">
                                        <input type={useNumPad ? "tel" : "text"} ref={inputEcoRef} className="form-control" name="ecoNr" placeholder="Artikelnummer" aria-label="Artikelnummer" onChange={(e) => inputChange(e)} onKeyUp={(e) => onKeyUp(e)} value={ecoNr} />
                                        <Button onClick={getQR} className="btn btn-info" type="button">Erstellen</Button>
                                    </div>
                                </div>
                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                                    {qr ? (<>
                                        <img src={qr} alt={ecoNr} height="200" width="200" />
                                    </>) : (<>
                                    <div className="my-5"></div>
                                    </>)}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default QrGenerator
