import axios from 'api/axios';
import React, { useState } from 'react'
import { Card, Col, Container, ListGroupItem, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AllProducts(props) {
  const [products, setProducts] = useState([]);
  const [loaded, setLoaded] = useState(false); //Sunst aktualisiert der immer?

  async function getProducts() {
    const { data } = await axios.get("/products", {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token")
      },
    });
    console.log(data)
    setProducts(data);
  }

  if (!loaded) {
    getProducts();
    setLoaded(true)
  }

  return (
    <>
      <Container className="px-3 mb-5">
      
        <Row className="mt-5">
        <h1 className='mb-3'>AllProducts</h1>
        <p>Dauert etwas zum Laden..</p>
          {products.map(p => {
            return (
              <Col key={p.id} sm="6" md="4" lg="3">
                <Card className="p-2">
                  <Container>
                    <Row className="py-3">
                    <Col>
                    <div className="w-100 mb-3 text-center">
                    <img src={'https://els-api.moetech.at/img?size=150&e=' + ((p.qNumber && !p.c1) ? p.qNumber : p.c1)} width="auto" height="auto" alt='' style={{ maxWidth: "100%", maxHeight: "150px" }} />
                    </div>
                        <h3 className="card-title mb-0">
                          {p.c1 ? p.c1 : ""}
                          {p.qNumber && !p.c1 ? p.qNumber : ""}
                          <span className='font-weight-light'>{p.qNumber && p.c1 ? " (" + p.qNumber + ")" : ""}</span>
                        </h3>
                        <p>{p.c4 ? p.c4 : <br />}</p>
                        <p>{p.c3}</p>
                        <Link to={"/product?e=" + (p.c1 ? p.c1 : p.qNumber)} className="btn btn-outline-secondary" type="button">Details</Link>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </Col>
            )
          })}
        </Row>
      </Container>
    </>
  )
}

export default AllProducts