import axios from 'api/axios';
import React, { useRef, useState } from 'react';
import { Button, Card, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function ProductDetail(props) {
  const search = useLocation().search;
  let eco = new URLSearchParams(search).get('e');
  let cust = new URLSearchParams(search).get('c');
  const [loaded, setLoaded] = useState(false); //Sunst aktualisiert der immer?
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState([]);
  const [showPrice, setShowPrice] = useState(false);
  const [tab, setTab] = useState('allgemein');
  const [ecoNr, setEcoNr] = useState("C");
  const [useNumPad, setUseNumPad] = useState(false);
  const inputEcoRef = useRef();
  const navigate = useNavigate();

  function inputChange(event) {
    switch (event.target.name) {
      case "ecoNr":
        setEcoNr(event.target.value);
        break;
      default:
    }
  }

  function onKeyUp(event) {
    if (event.key === "Enter") {
      switch (event.target.name) {
        case "ecoNr":
          eco = ecoNr;
          cust = "";
          getProduct()
          break;
        default:
      }
    }
  }

  function getProduct() {
    eco = eco ? eco : "";
    cust = cust ? cust : "";

    axios
      .get(`product?e=${eco}&c=${cust}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        }
      })
      .then(function (response) {
        console.log(response.data)
        setProducts(response.data);
      });
  }

  function getSettings() {
    axios
      .get(`settings`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token")
        }
      })
      .then(function (response) {
        setSettings(response.data)
      });
  }

  if (!loaded) {
    getSettings();
    getProduct();
    setLoaded(true)
  }

  function displayPrice(event) {
    event.preventDefault();

    if (showPrice === false) setShowPrice(true)
    else setShowPrice(false)
  }

  return (
    <>
      <Container className="px-3 mb-5">
        <Row className="mt-5">
          <Col lg="6" xl="3">
            <div className="input-group">
              <input type={useNumPad ? "tel" : "text"} ref={inputEcoRef} className="form-control" name="ecoNr" placeholder="Artikelnummer" aria-label="Artikelnummer" onChange={(e) => inputChange(e)} onKeyUp={(e) => onKeyUp(e)} value={ecoNr} />
              <Button onClick={(e) => {
                eco = ecoNr;
                cust = ""; getProduct();
              }} className="btn btn-info" type="button">Suchen</Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">

          {products.length === 0 ? (
            <>
              <Col xl="6">
                <Card>
                  <div className="p-4">
                    <h3 className="card-title">Fehler</h3>
                    <p>Die Suche konnte leider keine Ergebnisse liefern.</p>
                  </div>
                </Card>
              </Col>
            </>
          ) : (

            products.map((p, id) => {
              /*let p = product;
              console.log(p)
              Object.values(p).forEach(value => {
                if (value == null) value = "-";
              });
              console.log(p)
              */

              return (
                <Col key={id} lg="6">
                  <Card className="p-2">
                    <Container>

                      <Row className="py-3">
                        <Col md="6">

                          <h3 className="card-title mb-0">
                            {p.c1 ? p.c1 : ""}
                            {p.qNumber && !p.c1 ? p.qNumber : ""}
                            <span className='font-weight-light'>{p.qNumber && p.c1 ? " (" + p.qNumber + ")" : ""}</span>
                          </h3>
                          <p>{p.c4 ? p.c4 : <br />}</p>

                        </Col>
                        <Col md="6" className='text-center text-md-right'>
                          <img src={'https://els-api.moetech.at/img?e=' + (p.qNumber && !p.c1 ? p.qNumber : p.c1)} width="auto" height="auto" alt='' style={{ maxWidth: "100%", maxHeight: "200px" }} />
                        </Col>
                      </Row>
                    </Container>
                    <Card.Body className='pt-0'>
                      <Tabs
                        id="product-tabs"
                        activeKey={tab}
                        onSelect={(t) => setTab(t)}
                        className="mb-3"
                        variant="pills">
                        <Tab eventKey="allgemein" title="Allgemein" className='success'>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Cust. Description</div>
                                <div className="col">{p.c2}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Description</div>
                                <div className="col">
                                  {p.c3 ? (p.c3.split("\n").map((i, key) => {
                                    return (<><span key={key}>{i}</span><br /></>);
                                  })) : <br />}
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Supplier</div>
                                <div className="col">{p.c7}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">VK Preis</div>
                                <div className="col">{p.c54} USD</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0" onClick={(e) => displayPrice(e)}><div className="row">
                              <div className="col-md-4 fw-bold">EK Preis</div>
                              <div className="col">{showPrice === true ? (p.c8 + " USD") : (<>***** <i className='fas fa-eye'></i></>)}</div>
                            </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">FOB Port</div>
                                <div className="col">{p.c6}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">MOQ</div>
                                <div className="col">{p.c9}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Product size</div>
                                <div className="col">{p.c1 ? (
                                  [p.c28 ? "Ø: " + p.c28 : null,
                                  p.c29 ? "H: " + p.c29 : null,
                                  p.c30 ? "B: " + p.c30 : null,
                                  p.c31 ? "T: " + p.c31 : null].filter(x => x != null).join(' x ') + " cm") : (
                                  p.c28
                                )
                                }
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Materials</div>
                                <div className="col">{p.c24}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Color</div>
                                <div className="col">{p.c25}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Features</div>
                                <div className="col">{p.c26}</div>
                              </div>
                            </li>
                          </ul>
                        </Tab>

                        {/* Layout */}
                        <Tab eventKey="layout" title="Layout">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Cust. Description</div>
                                <div className="col">{p.c2}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Description</div>
                                <div className="col">
                                  {p.c3 ? (p.c3.split("\n").map((i, key) => {
                                    return (<><span key={key}>{i}</span><br /></>);
                                  })) : <br />}
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Layout</div>
                                <div className="col">{p.c51}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Box Type</div>
                                <div className="col">{p.c50}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">EAN Code</div>
                                <div className="col">{p.c5}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Safety Class</div>
                                <div className="col">{p.c10}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Protection Class</div>
                                <div className="col">{p.c11}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Materials</div>
                                <div className="col">{p.c24}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Color</div>
                                <div className="col">{p.c25}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Features</div>
                                <div className="col">{p.c26}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Product size</div>
                                <div className="col">{p.c1 ? (
                                  [p.c28 ? "Ø: " + p.c28 : null,
                                  p.c29 ? "H: " + p.c29 : null,
                                  p.c30 ? "B: " + p.c30 : null,
                                  p.c31 ? "T: " + p.c31 : null].filter(x => x != null).join(' x ') + " cm") : (
                                  p.c28
                                )
                                }
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Single Box Size</div>
                                <div className="col">
                                  H x B x T:&nbsp;
                                  {[p.c32 ? p.c32 : null,
                                  p.c33 ? p.c33 : null,
                                  p.c34 ? p.c34 : null].filter(x => x != null).join(' x ')} cm
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Master Box</div>
                                <div className="col">
                                  H x B x T: {p.c35} x {p.c36} x {p.c37} cm<br />
                                  Volume: {p.c38} m<sup>3</sup><br />
                                  Packing Unit: {p.c39}<br />
                                  Control: {p.c40}
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">ERP relevant</div>
                                <div className="col">{p.c68}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">ERP2.0 Artwork gesendet</div>
                                <div className="col">{p.c69}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Drop test class</div>
                                <div className="col">{p.c70}</div>
                              </div>
                            </li>
                          </ul>
                        </Tab>



                        {/* ALLGEMEIN */}
                        <Tab eventKey="alles" title="Alles">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Cust. Description</div>
                                <div className="col">{p.c2}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Description</div>
                                <div className="col">
                                  {p.c3 ? (p.c3.split("\n").map((i, key) => {
                                    return (<><span key={key}>{i}</span><br /></>);
                                  })) : <br />}
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">EAN Code</div>
                                <div className="col">{p.c5}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">FOB Port</div>
                                <div className="col">{p.c6}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Supplier</div>
                                <div className="col">{p.c7}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0" onClick={(e) => displayPrice(e)}><div className="row">
                              <div className="col-md-4 fw-bold">EK Preis</div>
                              <div className="col">{showPrice === true ? (p.c8 + " USD") : (<>***** <i className='fas fa-eye'></i></>)}</div>
                            </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">MOQ</div>
                                <div className="col">{p.c9}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Safety Class</div>
                                <div className="col">{p.c10}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Protection Class</div>
                                <div className="col">{p.c11}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 1 Socket</div>
                                <div className="col">{p.c12}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 1 Watt</div>
                                <div className="col">{p.c13}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 1 QTY</div>
                                <div className="col">{p.c14}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 1 Lumen. ERP 2.0 lightsource / with cover</div>
                                <div className="col">{p.c15}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 1 Color Temp</div>
                                <div className="col">{p.c16} K</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 1 included</div>
                                <div className="col">{p.c17}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 2 Socket</div>
                                <div className="col">{p.c18}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 2 Watt</div>
                                <div className="col">{p.c19}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 2 QTY</div>
                                <div className="col">{p.c20}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 2 Lumen</div>
                                <div className="col">{p.c21}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 2 Color Temp</div>
                                <div className="col">{p.c22} K</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Bulb 2 included</div>
                                <div className="col">{p.c23}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Materials</div>
                                <div className="col">{p.c24}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Color / Pantone Code</div>
                                <div className="col">{p.c25}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Features</div>
                                <div className="col">{p.c26}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Remote Type</div>
                                <div className="col">{p.c27}</div>
                              </div>
                            </li>
                            <h5 className="mt-4">Product Size:</h5>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Diameter</div>
                                <div className="col">{p.c28} cm</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Height</div>
                                <div className="col">{p.c29} cm</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Width</div>
                                <div className="col">{p.c30} cm</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Depth</div>
                                <div className="col">{p.c31} cm</div>
                              </div>
                            </li>
                            <h5 className="mt-4">Single Box Size:</h5>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Height</div>
                                <div className="col">{p.c32} cm</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Width</div>
                                <div className="col">{p.c33} cm</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Depth</div>
                                <div className="col">{p.c34} cm</div>
                              </div>
                            </li>
                            <h5 className="mt-4">Master Box Size:</h5>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Height</div>
                                <div className="col">{p.c35} cm</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Width</div>
                                <div className="col">{p.c36} cm</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Depth</div>
                                <div className="col">{p.c37} cm</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Volume</div>
                                <div className="col">{p.c38} m<sup>3</sup></div>
                              </div>
                            </li>
                            <li className="list-group-item px-0 mb-4">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Packing Unit</div>
                                <div className="col">{p.c39}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Control field: SingleBox to MB/pcs</div>
                                <div className="col">{p.c40}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Lamp Net Weight</div>
                                <div className="col">{p.c41} kg</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Single Box Gross Weight</div>
                                <div className="col">{p.c42} kg</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Master Box Gross Weight</div>
                                <div className="col">{p.c43} kg</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Master Box Net Weight</div>
                                <div className="col">{p.c44} kg</div>
                              </div>
                            </li>
                            <h5 className="mt-4">Single Box Packing Material: </h5>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Carton & Paper</div>
                                <div className="col">{p.c45} kg</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Plastic & bubble wrap</div>
                                <div className="col">{p.c46} kg</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0 mb-4">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Styrofoam</div>
                                <div className="col">{p.c47} kg</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Certificate</div>
                                <div className="col">{p.c48}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Powerfactor</div>
                                <div className="col">{p.c49}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Box Type</div>
                                <div className="col">{p.c50}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Layout</div>
                                <div className="col">{p.c51}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Customer</div>
                                <div className="col">{p.c52}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Shipping Mark Description</div>
                                <div className="col">{p.c53}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">VK Price</div>
                                <div className="col">{p.c54}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Transport EAN / Master Box EAN</div>
                                <div className="col">{p.c55}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Aktiver Artikel bei</div>
                                <div className="col">{p.c56}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Roller T-No.</div>
                                <div className="col">{p.c57}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Cusomter Genres Master Box</div>
                                <div className="col">{p.c58}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Drop test confirmed. Class 1 / 2 / no drop test</div>
                                <div className="col">{p.c59}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Drop test packaging update. Yes/No</div>
                                <div className="col">{p.c60}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Datum letzte Veränderung EK</div>
                                <div className="col">{p.c61}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Datum letzte Veränderung VK</div>
                                <div className="col">{p.c62}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Anlage Datum</div>
                                <div className="col">{p.c63}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Ausgemeldet</div>
                                <div className="col">{p.c64}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Ausmelden Datum</div>
                                <div className="col">{p.c65}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Lutz AT / DE IM</div>
                                <div className="col">{p.c66}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Baan Text Lutz Group</div>
                                <div className="col">{p.c67}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">ERP relevant</div>
                                <div className="col">{p.c68}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">ERP2.0 artwork gesendet</div>
                                <div className="col">{p.c69}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Drop test class</div>
                                <div className="col">{p.c70}</div>
                              </div>
                            </li>
                            <li className="list-group-item px-0">
                              <div className="row">
                                <div className="col-md-4 fw-bold">Baan Text</div>
                                <div className="col">{p.c71}</div>
                              </div>
                            </li>
                          </ul>
                        </Tab>
                      </Tabs>
                      { }
                      <div style={{
                        backgroundImage: "url(" + "https://els-api.moetech.at/qr?e=" + ((p.qNumber && !p.c1) ? p.qNumber : p.c1) + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        height: "200px",
                        width: "100%"
                      }}>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })
          )}
        </Row>
        <Row>
          <Col>
            <p>{products.length} von maximal 50 Artikeln werden angezeigt</p>
            {settings[1] ? (<p>Letztes Hochladen der Daten: {new Date(settings[1].value).toLocaleString()}</p>) : (<></>)}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProductDetail