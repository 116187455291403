import { wait } from '@testing-library/user-event/dist/utils';
import axios from 'api/axios';
import React, { useCallback, useState } from 'react';
import { Button, Row, Col, Card, Form, Container } from "react-bootstrap";
import Dropzone from 'react-dropzone';


function ImgUpload(props) {

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [progressInfos, setProgressInfos] = useState([]);
    const [message, setMessage] = useState([]);
    const [fileInfos, setFileInfos] = useState([]);
    let tempProgress = [];


    function onDrop(files) {
        let l = selectedFiles;

        if (files.length > 0) {
            files.forEach(element => {
                l.push(element)
            });
        }

        setSelectedFiles(l);
    }

    async function uploadFiles() {
        //props.showMessage("Uploading " + selectedFiles.length + " files.. not really");

        props.showMessage()

        for (let i = 0; i < selectedFiles.length; i++) {
            tempProgress.push({ percentage: 0, fileName: selectedFiles[i].name });
        }

        console.log(selectedFiles)
        for (let i = 0; i < selectedFiles.length; i++) {
            upload(i, selectedFiles[i]);
        }

        setMessage([0]);

    }


    /* Upload to server */
    async function upload(idx, file) {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("filename", file.name);

        axios.post("/upload/image", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "x-access-token": localStorage.getItem("token")
            },
            onUploadProgress: progressEvent => {
                tempProgress[idx].percentage = Math.round(
                    (100 * progressEvent.loaded) / progressEvent.total
                );
            }
        }).then((response) => {
            setMessage([...message, response.data.message])
            setProgressInfos(tempProgress);
            return;
        }).catch(error => {
            console.log("Catch")
            tempProgress[idx].percentage = 0;
            setProgressInfos(tempProgress);
            props.showError(file.name + " wurde nicht hochgeladen")
            return
        })

    }

    return (
        <Container className="mb-5">
            <Row className="my-3">
                <Col>
                    <h1>Bilder hochladen</h1>
                    <ul>
                        <li>Wenn möglich, die Bilder mit einer Mindestbreite/Höhe von 1024px hochladen. Das System erstellt dann automatisch kleinere Varianten mit 1024px, 500px und 150px.</li>
                        <li>Die Dateinamen müssen die <strong>exakte Artikelnummer</strong> sein. (z.B. C14001MM.jpg)</li>
                        <li>Bereits vorhandene Bilder werden überschrieben (gut für neue Fotos vom Fotografen)</li>
                        <li>Nur .jpg als Dateiformat</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <div>
                    {progressInfos &&
                        progressInfos.map((progressInfo, index) => (
                            <div className="mb-2" key={index}>
                                <span>{progressInfo.fileName}</span>
                                <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-info"
                                        role="progressbar"
                                        aria-valuenow={progressInfo.percentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: progressInfo.percentage + "%" }}
                                    >
                                        {progressInfo.percentage}%
                                    </div>
                                </div>
                            </div>
                        ))}
                    {selectedFiles &&
                        Array.isArray(selectedFiles) &&
                        selectedFiles.length > 0 ? (
                        <>
                            {selectedFiles.length == 1 ? ("1 Bild") : (selectedFiles.length + " Bilder")}
                        </>
                    ) : (
                        ""
                    )}
                    <div className="">
                        <Dropzone onDrop={onDrop} accept={{'image/jpeg':[]}}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input {...getInputProps()} />
                                        {selectedFiles &&
                                            Array.isArray(selectedFiles) &&
                                            selectedFiles.length ? (
                                            <div className="selected-file">
                                                {selectedFiles.length > 30
                                                    ? "" //`${selectedFiles.length} files`
                                                    : selectedFiles.map((file) => file.name).join(", ")}<br /><br />
                                                {selectedFiles.length == 1 ? ("1 Bild") : (selectedFiles.length + " Bilder")}
                                            </div>

                                        ) : (
                                            `Drag and drop files here, or click to select files`
                                        )}
                                    </div>
                                    <aside className="selected-file-wrapper">
                                        <button
                                            className="btn btn-success mt-3"
                                            disabled={!selectedFiles}
                                            onClick={uploadFiles}
                                        >
                                            Upload
                                        </button>
                                    </aside>
                                </section>
                            )}
                        </Dropzone>
                    </div>

                    {/*message.length > 0 && (
                        <div className="alert alert-secondary" role="alert">
                            <ul>
                                {message.map((item, i) => {
                                    return <li key={i}>{item}</li>;
                                })}
                            </ul>
                        </div>
                            )*/}

                    {fileInfos.length > 0 && (
                        <div className="card">
                            <div className="card-header">List of Files</div>
                            <ul className="list-group list-group-flush">
                                {fileInfos &&
                                    fileInfos.map((file, index) => (
                                        <li className="list-group-item" key={index}>
                                            <a href={file.url}>{file.name}</a>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}

                </div>
            </Row>
        </Container>
    )
}

export default ImgUpload
