import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, FormGroup, InputGroup, Row } from 'react-bootstrap';
import axios from 'api/axios';

function Login(props) {
    const [username, setUsername] = useState("viewer");
    const [password, setPassword] = useState("");

    const handlePwdChange = (event) => {
        setPassword(event.target.value);
    };

    const login = (e) => {
        e.preventDefault();
        axios.post("/login", {
            username: username,
            password: password,
        }, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            console.log(response)
            if (!response.data.auth) {
                props.setLoginStatus(false);
                props.showError(response.data.message);
            }
            else {
                localStorage.setItem("token", response.data.token);
                props.setLoginStatus(true);
            }
        }).catch(error => {
            props.showError("Server-Error");
        })
    }

    function onKeyUp(event) {
        if (event.key === "Enter") {
            login(event);
        }
    }

    return (
        <Container className="pt-2 pt-sm-5 pb-4 pb-sm-5">
            <Row className="justify-content-center">
                <Col xxl="4" lg="5">
                    <Card>
                        <Card.Header className="pt-4 pb-4 text-center text-light bg-info">
                            <h1>Anmelden</h1>
                        </Card.Header>
                        <Card.Body>
                            <p className='text-center'>
                                Sie müssen sich anmelden, um auf das System zugreifen zu können.
                            </p>
                            <FormGroup>
                                <label htmlFor="password">Zugangscode</label>
                                <InputGroup className="input-group-merge">
                                    <input name='password' type="password" onChange={(e) => handlePwdChange(e)} onKeyUp={(e) => onKeyUp(e)} value={password} placeholder="Enter Password" className="form-control" />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-0 text-center">
                                <button type="button" className="btn rounded-pill btn-info mt-2" onClick={(e) => login(e)}>Login</button>
                            </FormGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Login
