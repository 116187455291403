import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import Layout from './Components/Layout';
import Dashboard from './Components/Dashboard';
import NoPage from './Components/NoPage';
import ProductDetail from './Components/ProductDetail';
import QrGenerator from './Components/QrGenerator';
import QrScanner from './Components/QrScanner';
import Login from 'Components/Login';
import axios from 'api/axios';
import AllProducts from 'Components/AllProducts';
import ImgUpload from 'Components/ImgUpload';

function App() {

  const [user, setUser] = useState();
  const [loginStatus, setLoginStatus] = useState(false);

  const userAuthenticated = () => {
    axios.get("/checkPermission", {
      headers: {
        "x-access-token": localStorage.getItem("token")
      },
    }).then((response) => {
      if (!response.data.auth) { setLoginStatus(false) }
      else { setLoginStatus(true); }
    }).catch(() => {
      setLoginStatus(false);
    })
  }

  userAuthenticated();

  function showError(text) {
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  function showMessage(text) {
    toast.success(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  }

  return (
    <>
     <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loginStatus ? (<BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Dashboard showError={showError} showMessage={showMessage} setLoginStatus={setLoginStatus} />} />
            <Route path="products" element={<AllProducts showError={showError} showMessage={showMessage}/>} />
            <Route path="product" element={<ProductDetail showError={showError} showMessage={showMessage}/>} />
            <Route path="qr-scan" element={<QrScanner showError={showError} showMessage={showMessage}/>} />
            <Route path="qr-gen" element={<QrGenerator showError={showError} showMessage={showMessage}/>} />
            <Route path="img-upload" element={<ImgUpload showError={showError} showMessage={showMessage}/>} />
            <Route path="*" element={<Dashboard showError={showError} showMessage={showMessage} />}  /*<NoPage />*/ />
          </Route>
        </Routes>
      </BrowserRouter >) :
        (<Login showError={showError} showMessage={showMessage} loginStatus={loginStatus} setLoginStatus={setLoginStatus} />)}
    </>
  );
}

export default App;
