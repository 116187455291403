import React, { useEffect, useRef, useState } from 'react';
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


function Dashboard(props) {
  const [ecoNr, setEcoNr] = useState("C");
  const [custNr, setCustNr] = useState("8227");
  const [useNumPad, setUseNumPad] = useState(true);
  const inputEcoRef = useRef();
  const inputCustRef = useRef();
  const navigate = useNavigate();

  function inputChange(event) {
    switch (event.target.name) {
      case "ecoNr":
        setEcoNr(event.target.value);
        break;
      case "custNr":
        setCustNr(event.target.value);
        break;
      default:
    }
  }

  function onKeyUp(event) {
    if (event.key === "Enter") {
      switch (event.target.name) {
        case "ecoNr":
          navigate(`product?e=${ecoNr}`);
          break;
        case "custNr":
          navigate(`product?c=${custNr}`);
          break;
        default:
      }
    }
  }


  useEffect(() => {
    inputCustRef.current.focus();
    inputEcoRef.current.focus();
  }, [useNumPad])

  function logout(event) {
    event.preventDefault();
    localStorage.removeItem("token");
    props.setLoginStatus(false);
    navigate('/');
  }

  return (<>
    {/*  <section className="hero-section bg pt-3 pb-5">
      <div className="container px-3">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div className="mt-4 mt-xl-0">
              <h1 className="text-white fw-bold mb-1 mt-3 hero-title">
                Economic Lighting
              </h1>
              <p className="mb-4 font-16 text-white-50">Produkt Datenbank</p>
              <Link to="/moneyCounter" name="moneyCounter" className="btn btn-warning btn-dm-dark">Geldzähler</Link>
            </div>
          </div>
          <div className="col-md-5 offset-md-2">
            <div className="text-md-end mt-3 mt-md-0">
            </div>
          </div>
        </div>
      </div>
    </section>  */}
    <Container className="px-3 mb-5">
      <Row className="mt-3">
        <Col>
          <div className="btn float-right px-0" onClick={(e) => logout(e)} role="button">
            <i className="fa-solid fa-right-from-bracket"></i> Logout
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg="6">
          <Card>
            <Card.Body>
              <h3>Artikel finden</h3>
              <p>Nur Zahlen bei Tastatur verwenden (mobil)</p>
              <input type="checkbox" id="switch2" data-switch="info" checked={useNumPad} onChange={(e) => setUseNumPad(e.target.checked)} />
              <label htmlFor="switch2" data-on-label="Ein" data-off-label="Aus"></label>
              <div className="my-3">
                <label className="form-label">Economic Art-Nr.</label>
                <div className="input-group">
                  <input type={useNumPad ? "tel" : "text"} ref={inputEcoRef} className="form-control" name="ecoNr" placeholder="Artikelnummer" aria-label="Artikelnummer" onChange={(e) => inputChange(e)} onKeyUp={(e) => onKeyUp(e)} value={ecoNr} />
                  <Link to={"/product?e=" + ecoNr} className="btn btn-info" type="button">Suchen</Link>
                </div>
              </div>
              <div className="my-3">
                <label className="form-label">Kunden Art-Nr.</label>
                <div className="input-group">
                  <input type={useNumPad ? "tel" : "text"} ref={inputCustRef} className="form-control" name="custNr" placeholder="Artikelnummer" aria-label="Artikelnummer" onChange={(e) => inputChange(e)} onKeyUp={(e) => onKeyUp(e)} value={custNr} />
                  <Link to={"/product?c=" + custNr} className="btn btn-info" type="button">Suchen</Link>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="3">
          <Card>
            <Card.Body>
              <h3>QR Code Generator</h3>
              <p>QR Code Link generieren</p>
              <Link to="/qr-gen" className="btn rounded-pill btn-success">Öffnen</Link>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <h3>Bilder hochladen</h3>
              <p>Für Excel Listen, etc.</p>
              <Link to="/img-upload" className="btn rounded-pill btn-success">Öffnen</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="3">
          <Card>
            <Card.Body>
              <h3>QR Code Scanner</h3>
              <p>QR Code hier Scannen</p>
              <Link to="/qr-scan" className="btn rounded-pill btn-success">Öffnen</Link>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <h3>Alle Artikel</h3>
              <p>Nur im Firmennetz öffnen</p>
              <Link to="/products" className="btn rounded-pill btn-secondary">Öffnen</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="3">
          <Card>
            <Card.Body>
              <h3>Angebotsblatt</h3>
              <p>Automatisch PDF-Angebotsblätter erstellen &#40;ohne Bild&#41;</p>
              <Link to="/" className="btn rounded-pill btn-secondary">Coming eventually?</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="3">
          <Card>
            <Card.Body>
              <h3>Daten updaten</h3>
              <p>Excel-File hier hochladen</p>
              <Link to="/" className="btn rounded-pill btn-secondary">Coming eventually?</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>);
}

export default Dashboard;